import React from "react"
import { isFirefox } from 'react-device-detect';

const CardBig = (props) => {
  return (
    <div className={isFirefox ? 'cardBig cardBig--firefox' : 'cardBig'}>
      <div className='cardBig__content'>
        <h2>{props.title}</h2>
        <h5>{props.slogan}</h5>
        {props.links != null && <div className='cardBig__box'>
          {props.links.map((element) => (
            <a href={element.url} className='cardBig__box__link'><li className='cardBig__box__li'>{element.title}</li></a>
          ))}
        </div>}
        {props.children}
      </div>
    </div>
  )
}

export default CardBig
