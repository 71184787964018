import React, {useEffect} from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import CardBig from "../components/cardBig"
import CardWithImage from "../components/cardWithImage"
import FreieZimmer from "../components/freieZimmer"

import { isFirefox } from 'react-device-detect';

const Start = ({ data, location }) => {
  // const siteTitle = data.site.siteMetadata?.title || `Title`
  // const posts = data.allMdx.nodes
  
  // Single Image Data
  const images = {
    card2: data.cardImg2.childImageSharp.fluid,
    card3: data.cardImg3.childImageSharp.fluid,
    card4: data.cardImg4.childImageSharp.fluid,
    card5: data.cardImg5.childImageSharp.fluid,
    cardImgWohl: data.cardImgWohl.childImageSharp.fluid,
    logo: data.bannauLogo.childImageSharp.fluid,
  }
  return (
    <Layout title="Startseite" slogan="Wilkommen im Altersheim Bannau" fullwidth={true}>
      <div className="cardContainer">
        <CardBig
          title="Altersheim Bannau"
          slogan="Das Altersheim Bannau versteht sich als offenes Haus und pflegt guten Kontakt zu Angehörigen, Freunden und zur Öffentlichkeit">
        </CardBig>
        {/* <Link to="/covid-19/" style={{textDecoration: "none"}}>
        <div className={isFirefox ? 'cardBig cardBig-alt cardBig-alt--firefox' : 'cardBig cardBig-alt'}>
          <div className="infoIcon"></div>
          <h5 style={{textAlign: "center"}}>Aktuelle Covid-19 <br/>Bestimmungen</h5>
        </div>
        </Link> */}
      </div>
      <div className='main-bar'>
        <CardWithImage image={images.cardImgWohl}>
          <h3>Sich wohlfühlen</h3>
          <p>Altersturnen, Vorlesen, Spielnachmittage und Werken bringen Anregung, Spass und Freude. Eine beliebte Abwechslung sind die Ausflüge mit der Plauschgruppe, welche mehrmals jährlich stattfinden. Zudem bieten unsere freiwilligen Helfer wöchentliche Spaziergänge und Fahrten ins Heimatdorf. Altersturnen, Vorlesen, Spielnachmittage und Werken bringen Anregung, Spass und Freude. Eine beliebte Abwechslung sind die Ausflüge mit der Plauschgruppe, welche mehrmals jährlich stattfinden. Zudem bieten unsere freiwilligen Helfer wöchentliche Spaziergänge und Fahrten ins Heimatdorf.</p>
        </CardWithImage>

        <CardWithImage image={images.card2} direction="reverse">
          <h3>Gemeinschaft pflegen</h3>
          <p>Das Altersheim Bannau liegt zentrumsnah in beschaulicher schöner Umgebung. Es ermöglicht seinen Bewohnern ein würdiges Leben in grösstmöglicher Selbstbestimmung und Eigenverantwortung. Das Heim bietet qualitativ ausgezeichnete Betreuung und leichte Pflege in familiärer Atmosphäre.</p>
        </CardWithImage>

        <CardWithImage image={images.card3}>
          <h3>Eigenständigkeit</h3>
          <p>Das Altersheim Bannau liegt zentrumsnah in beschaulicher schöner Umgebung. Es ermöglicht seinen Bewohnern ein würdiges Leben in grösstmöglicher Selbstbestimmung und Eigenverantwortung. Das Heim bietet qualitativ ausgezeichnete Betreuung und leichte Pflege in familiärer Atmosphäre.</p>
        </CardWithImage>

        <div style={{display: 'flex', flexWrap: 'wrap', backgroundColor: '#ffffff', marginBottom: '48px'}}>
          <Image fluid={images.card4} style={{flex: '1 1 250px', height: '400px'}} />
          <p style={{flex: '1 0 100%', padding: '2rem'}}>
            Das Altersheim der Evangelischen Kirchgemeinde steht im Dienste betagter Menschen, die noch selbständig sind oder leichte Pflege benötigen. Es bietet 29 Personen ein liebevolles neues Zuhause. Die überschaubare familiäre Grösse ermöglicht einen raschen Kontakt. Die ruhige und zentrumsnahe Lage lädt zu Spaziergängen ein.
            <br />Die Persönlichkeit und die Privatsphäre unserer Bewohner respektieren und achten wir. Menschlichkeit und Wärme in der täglichen Begegnung sind uns wichtig. Wir unterstützen Selbständigkeit und Eigenverantwortung. Auf die Bedürfnisse der Bewohner gehen wir ein und legen Wert auf gut informierte und zufriedene HeimBewohner. Wir führen ein offenes Haus und schätzen einen guten Kontakt zu Angehörigen, Bekannten und zur Öffentlichkeit.
            <br />Wir legen Wert auf motiviertes, fachlich gut ausgebildetes Personal, das sich regelmässig weiterbildet. Auch freiwillige Helfer engagieren sich zum Wohl der Bewohner.
            <br />Das Heim wird selbsttragend nach ökonomischen und ökologischen Grundsätzen geführt. Es weist ein ausgewogenes Preis-/Leistungsverhältnis auf. Die Heimleitung wird unterstützt von der Heimkommission.</p>
        </div> 

      </div>
      <div className='main-side-spacer'></div>
      <div className="side-bar">
        <Link to="/angebot/altersheim/#freie-zimmer/" style={{textDecoration: "none"}}>
          <div className="freie-zimmer">
            <Image fluid={images.logo} />
            <h5>Freie Zimmer: <FreieZimmer/></h5>
          </div>
        </Link>
        <Link to="/formulare-dokumente/" style={{textDecoration: "none"}}>
          <div className="sidebar-box" style={{display: 'flex', alignItems: 'center'}}>
            <span style={{marginRight: '1em', height: '100%'}} role="img" aria-label="Dokumente">📜</span><h5 style={{margin: '0'}}> Wichtige Formulare & Dokumente</h5>
          </div>
        </Link>
        <Link to="/tagebuch/" style={{textDecoration: "none"}}>
          <div className="sidebar-box">
            <h5 style={{margin: '0'}}><span style={{marginRight: '0.5em'}} role="img" aria-label="Tagebuch">📖</span> Tagebuch</h5>
          </div>
        </Link>
        <Link to="/termine/" style={{textDecoration: "none"}}>
          <div className="sidebar-box">
            <h5 style={{margin: '0'}}><span style={{marginRight: '0.5em'}} role="img" aria-label="Termine">🗓️</span> Termine</h5>
          </div>
        </Link>
        <Link to="/google-bewerten/" style={{textDecoration: "none"}}>
          <div className="sidebar-box">
            <h5 style={{margin: '0'}}><span style={{marginRight: '0.5em'}} role="img" aria-label="Bewerten">⭐️</span> Jetzt auf Google bewerten</h5>
          </div>
        </Link>
      </div>
      
    </Layout>
  )
}

export default Start

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    },
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    },
    cardImg2: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-gemeinschaft-pflegen.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    cardImg3: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-eigenständigkeit.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    cardImg4: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    cardImg5: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-garten.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    cardImgWohl: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-wohl-fuehlen.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    bannauLogo: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
  }
`
