import React from "react"
import Image from "gatsby-image"

const CardWithImage = (props) => {
  return (
    <div className={props.direction === 'reverse' ? 'cardWithImage cardWithImage--reverse' :'cardWithImage'}>
      {props.image != null && 
        <Image className='cardWithImage__img' fluid={props.image} />
      }
      <div className='cardWithImage__content'>
        {props.children}
      </div>
    </div>
  )
}

export default CardWithImage
